import React, { createContext, useEffect, useState } from "react";

export const ThemeContext = createContext();

export const ThemeProvider = ({children}) => {
    const [darkMode, setDarkMode] = useState(
        JSON.parse(localStorage.getItem('dark-theme') || false)
    );

    useEffect(() => {
        if(localStorage.getItem('dark-theme') === null){
            setDarkMode(true);
            localStorage.setItem('dark-theme', true)
        }
    }, []);

    return (
        <ThemeContext.Provider value={{darkMode, setDarkMode}}>
            {children}
        </ThemeContext.Provider>
    )
}